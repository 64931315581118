import React from 'react';

import AuthenticationLayout from '~/layouts/authentication';
import { LinkComponent } from '~/components/Links';

import { NewPasswordForm } from '~/components/forms';

const ResetPassword: React.FC = () => {
  return (
    <AuthenticationLayout
      title="New Password"
      primaryHeading="Create a New Password"
      primaryDescription=""
      primaryForm={<NewPasswordForm />}
      secondaryForm={<div />}
    >
      <LinkComponent href="/sign-in" arrow>
        Sign in
      </LinkComponent>
    </AuthenticationLayout>
  );
};

export default ResetPassword;
