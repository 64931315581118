import React from 'react';
import { Link } from 'gatsby';

import { Arrow } from '~/components/Icons';


interface ArrowLinkProps {
  href: string;
  arrow?: boolean;
}


export const LinkComponent: React.FC<ArrowLinkProps> = ({ children, href, arrow }) => {
  if (!arrow) {
    return <a href={href} className="cursor-pointer text-sm text-purple-900 font-bold focus:underline hower:underline">{children}</a>;
  }

  return (
    <Link className="flex justify-start items-center gap-2" to={href}>
      <div className="w-3 h-3 mr-2">
        <Arrow />
      </div>
      <p className="cursor-pointer text-sm text-purple-900 font-medium focus:underline hower:underline">{children}</p>
    </Link>
  );
};

export default LinkComponent;
